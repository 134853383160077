@import url("https://fonts.googleapis.com/css?family=EB+Garamond&display=swap"); /* redundant? */

body.graphic-design{
  font: 21px/28px "EB Garamond", serif;
}
.graphic-design__container{
  padding-top: 140px;
}
.graphic-design__caption{
  max-width: 560px;
}