.row{ max-width: 1200px; padding: 0; margin: 0 auto; overflow: hidden; }
.inset-row{ margin-left: -15px; margin-right: -15px; }
.col-xs-0, .col-sm-0, .col-md-0, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{ position: relative; min-height: 1px; padding-left: 15px; padding-right: 15px; }
.col-xs-0, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12{ float: left; }
.col-xs-12{ width: 100%; }
.col-xs-11{ width: 91.66666666666666%; }
.col-xs-10{ width: 83.33333333333334%; }
.col-xs-9{ width: 75%; }
.col-xs-8{ width: 66.66666666666666%; }
.col-xs-7{ width: 58.333333333333336%; }
.col-xs-6{ width: 50%; }
.col-xs-5{ width: 41.66666666666667%; }
.col-xs-4{ width: 33.33333333333333%; }
.col-xs-3{ width: 25%; }
.col-xs-2{ width: 16.666666666666664%; }
.col-xs-1{ width: 8.333333333333332%; }
.col-centered{ float: none; margin: 0 auto; }


@media screen and ( min-width: 768px ){

  .row{ width: 85%; }
  .col-sm-0, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12{ float: left; }
  .col-sm-12{ width: 100%; }
  .col-sm-11{ width: 91.66666666666666%; }
  .col-sm-10{ width: 83.33333333333334%; }
  .col-sm-9{ width: 75%; }
  .col-sm-8{ width: 66.66666666666666%; }
  .col-sm-7{ width: 58.333333333333336%; }
  .col-sm-6{ width: 50%; }
  .col-sm-5{ width: 41.66666666666667%; }
  .col-sm-4{ width: 33.33333333333333%; }
  .col-sm-3{ width: 25%; }
  .col-sm-2{ width: 16.666666666666664%; }
  .col-sm-1{ width: 8.333333333333332%; }
  .col-lg-1:nth-child(12n+1), .col-lg-2:nth-child(6n+1), .col-lg-3:nth-child(4n+1), .col-lg-4:nth-child(3n+1), .col-lg-6:nth-child(2n+1), .col-md-1:nth-child(12n+1), .col-md-2:nth-child(6n+1), .col-md-3:nth-child(4n+1), .col-md-4:nth-child(3n+1), .col-md-6:nth-child(2n+1){ clear: none; }
  .col-sm-1:nth-child(12n+1), .col-sm-2:nth-child(6n+1), .col-sm-3:nth-child(4n+1), .col-sm-4:nth-child(3n+1), .col-sm-6:nth-child(2n+1){ clear: left; }
  .col-centered{ float: none; margin: 0 auto; }

}


@media screen and ( min-width: 992px ){

  .col-md-0, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12{ float: left; }
  .col-md-12{ width: 100%; }
  .col-md-11{ width: 91.66666666666666%; }
  .col-md-10{ width: 83.33333333333334%; }
  .col-md-9{ width: 75%; }
  .col-md-8{ width: 66.66666666666666%; }
  .col-md-7{ width: 58.333333333333336%; }
  .col-md-6{ width: 50%; }
  .col-md-5{ width: 41.66666666666667%; }
  .col-md-4{ width: 33.33333333333333%; }
  .col-md-3{ width: 25%; }
  .col-md-2{ width: 16.666666666666664%; }
  .col-md-1{ width: 8.333333333333332%; }
  .col-lg-1:nth-child(12n+1), .col-lg-2:nth-child(6n+1), .col-lg-3:nth-child(4n+1), .col-lg-4:nth-child(3n+1), .col-lg-6:nth-child(2n+1), .col-sm-1:nth-child(12n+1), .col-sm-2:nth-child(6n+1), .col-sm-3:nth-child(4n+1), .col-sm-4:nth-child(3n+1), .col-sm-6:nth-child(2n+1){ clear: none; }
  .col-md-1:nth-child(12n+1), .col-md-2:nth-child(6n+1), .col-md-3:nth-child(4n+1), .col-md-4:nth-child(3n+1), .col-md-6:nth-child(2n+1){ clear: left; }
  .col-centered{ float: none; margin: 0 auto; }

}


@media screen and ( min-width: 1200px ){

  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12{ float: left; }
  .col-lg-12{ width: 100%; }
  .col-lg-11{ width: 91.66666666666666%; }
  .col-lg-10{ width: 83.33333333333334%; }
  .col-lg-9{ width: 75%; }
  .col-lg-8{ width: 66.66666666666666%; }
  .col-lg-7{ width: 58.333333333333336%; }
  .col-lg-6{ width: 50%; }
  .col-lg-5{ width: 41.66666666666667%; }
  .col-lg-4{ width: 33.33333333333333%; }
  .col-lg-3{ width: 25%; }
  .col-lg-2{ width: 16.666666666666664%; }
  .col-lg-1{ width: 8.333333333333332%; }
  .col-md-1:nth-child(12n+1), .col-md-2:nth-child(6n+1), .col-md-3:nth-child(4n+1), .col-md-4:nth-child(3n+1), .col-md-6:nth-child(2n+1), .col-sm-1:nth-child(12n+1), .col-sm-2:nth-child(6n+1), .col-sm-3:nth-child(4n+1), .col-sm-4:nth-child(3n+1), .col-sm-6:nth-child(2n+1){ clear: none; }
  .col-lg-1:nth-child(12n+1), .col-lg-2:nth-child(6n+1), .col-lg-3:nth-child(4n+1), .col-lg-4:nth-child(3n+1), .col-lg-6:nth-child(2n+1){ clear: left; }
  .col-centered{ float: none; margin: 0 auto; }

}