.container{
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}

.container:first-child{
  border-top: 0;
}

.headline{
  padding: 15px;
  margin: 0;
  font-size: 21px;
  line-height: 33px;
  cursor: pointer;
}
.date{
  position: relative;
}
.date:after{
  content: '';
  position: absolute;
  top: 5px;
  right: -15px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid rgba( 0, 0, 0, 0.87 );
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.headline:hover .date:after,
.open .date:after{
  right: -18px;
}
.open .date:after{
  -webkit-transform: rotate( 90deg );
  transform: rotate( 90deg );
}
.list{
  list-style: none;
  display: none; /* temp */
  padding: 0;
  background-color: #fafafa;
}
.open .list{
  display: block; /* temp */
}
