.container{
  margin-bottom: 30px;
}
.container .playlists{
  max-height: 600px;
  background-color: #fafafa;
  overflow: auto;
}
.container label{
  display: block;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.container label span{
  display: block;
  padding: 15px;
  cursor: pointer
}
.container label:nth-child( even ){
  background-color: #f2f2f2;
}
.container input[ type="checkbox" ]{
  display: none;
}
.container input[ type="checkbox" ]:checked + span{
  /*background-color: rgba( 151, 84, 250, 0.5 );*/
  background-color: rgba( 0, 0, 0, 0.4 );
}
.container label span:hover,
.container input[ type="checkbox" ]:checked + span:hover{
  background-color: #9754fa;
}
