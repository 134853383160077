*,
*:before,
*:after,
input[ type="search" ]{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
html{
	min-height: 100%;
}
body{
	background-color: #fff;
	/*color: #292929;*/
  color: rgba( 0, 0, 0, 0.87 );
  text-rendering: optimizeLegibility;
}
button{
	padding: 0;
	border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
button::-moz-focus-inner{
	padding: 0;
	border: 0;
}
img{
	max-width: 100%;
	height: auto;
}