@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");

body:after {
  -moz-transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
}
.show-menu-overlay {
  overflow: hidden;
}
.menu-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 80px;
  margin: 0 auto;
  font-family: "Inconsolata", monospace;
}
button.menu {
  position: absolute;
  /*top: 57px;*/
  top: 37px;
  left: 0;
  z-index: 11;
  display: none; /* temporary */
  width: 55px;
  height: 55px;
}
button.menu div,
button.menu span,
.menu-overlay g {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
button.menu,
button.menu div,
button.menu span {
  -moz-transition: -moz-transform 300ms ease-out;
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
}
button.menu div,
button.menu span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 1px;
  margin: auto;
}
button.menu div {
  background-color: #292929;
}
/*body:not( .show-menu-overlay ) button.menu span{*/
/*html:not( .splash ) button.menu span{*/
button.menu span {
  background-color: #292929 /* !important*/;
}
.show-menu-overlay button.menu:hover {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.show-menu-overlay button.menu div {
  background-color: transparent;
}
button.menu span:first-child {
  -ms-transform: translateY(-12px);
  -webkit-transform: translateY(-12px);
  transform: translateY(-12px);
}
button.menu:hover span:first-child {
  -ms-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.show-menu-overlay button.menu span:first-child {
  -ms-transform: translateY(0) rotate(45deg);
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
button.menu span:last-child {
  -ms-transform: translateY(12px);
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
}
button.menu:hover span:last-child {
  -ms-transform: translateY(15px);
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}
.show-menu-overlay button.menu span:last-child {
  -ms-transform: translateY(0) rotate(-45deg);
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}
.social {
  float: right;
  list-style: none;
  padding: 0;
  /*margin: 54px 0 0;*/
  margin: 30px 0 0;
  line-height: 18px;
}
.social__item {
  margin-bottom: 11px;
}
.social__link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 22px;
}
.social__hover-text {
  display: none;
}
/*.social__link:hover .social__hover-text { maybe later
  display: inline;
}*/
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 0;
  /*background-color: #fff;*/
  background-color: rgba(255, 255, 255, 0.95);
  /*text-align: center;*/
  opacity: 0;
  overflow-y: auto;
  -moz-transition: opacity 200ms ease-out, z-index 1ms 201ms, width 1ms 201ms,
    height 1ms 201ms;
  -webkit-transition: opacity 200ms ease-out, z-index 1ms 201ms, width 1ms 201ms,
    height 1ms 201ms;
  transition: opacity 200ms ease-out, z-index 1ms 201ms, width 1ms 201ms,
    height 1ms 201ms;
}
.show-menu-overlay .menu-overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  -moz-transition: opacity 200ms ease-out;
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
.menu-overlay > div {
  position: relative;
  height: 100%;
  padding-top: 116px;
  opacity: 0;
  -moz-transition: opacity 200ms ease-out;
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
.show-menu-overlay .menu-overlay > div {
  opacity: 1;
  -moz-transition: opacity 400ms 200ms ease-out;
  -webkit-transition: opacity 400ms 200ms ease-out;
  transition: opacity 400ms 200ms ease-out;
}
.menu-overlay__type {
  position: relative;
  margin-bottom: 90px;
}
.menu-overlay__highlight-home,
.menu-overlay__highlight-page {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #ff007f; /* temp */
  opacity: 0;
  -moz-transition: all 100ms ease-out;
  -webkit-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}
.menu-overlay__highlight-home--on,
.menu-overlay__highlight-page--on {
  opacity: 1;
}
.menu-overlay g {
  position: relative;
}
.menu-overlay g:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ff0;
}
.menu-overlay svg {
  width: 100%;
  height: 100%;
}
.menu-overlay svg.on {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-overlay__font-large {
  display: none;
}
.menu-overlay path {
  fill: #292929;
}
path.diagonal {
  fill: none;
  stroke: #292929;
}
.menu-overlay button,
.menu-overlay a {
  position: absolute;
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* fixes <ie11 glitch where transparent links are not click-able when elements behind them have color */
}
.menu-overlay a {
  height: 17.3690065124788%;
}
a.home,
.menu-overlay__highlight-home {
  top: 0;
  left: 0;
  width: 59.1547317506144%;
  height: 10.7598690714176%;
}
.menu-overlay a.global-warming {
  top: 29.9080743952342%;
  left: 15.9524995455801%;
  width: 79.0708352119957%;
}
.menu-overlay a.playlist-concerts {
  top: 56.2695339413777%;
  left: 16.2697672661862%;
  width: 83.7302327338138%;
}
.menu-overlay a.graphic-design {
  top: 82.6309934875212%;
  left: 15.9524995455801%;
  width: 76.4035673733585%;
}
.menu-overlay h1,
.menu-overlay ul {
  font-size: 0;
  text-indent: -999999px;
}

@media screen and (min-width: 768px) {
  .social__item {
    float: left;
    margin: 0 0 0 22px;
  }
  .menu-overlay svg.menu-overlay__font-small {
    display: none;
  }
  .menu-overlay__font-large {
    display: inline;
  }
  .menu-overlay a {
    height: 20.8443182586358%;
  }
  a.home,
  .menu-overlay__highlight-home {
    width: 35.9034214549158%;
    height: 7.7471487479599%;
  }
  .menu-overlay a.global-warming {
    top: 26.4270745816071%;
    left: 14.1422971782034%;
    width: 79.7555057284148%;
  }
  .menu-overlay a.playlist-concerts {
    top: 52.7913781614856%;
    left: 14.4573658979163%;
    width: 85.5426341020837%;
  }
  .menu-overlay a.graphic-design {
    top: 78.9714729939836%;
    left: 14.1422971782034%;
    width: 77.0647219905444%;
  }
}
