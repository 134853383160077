.text{
  margin: 0 0 33px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@media screen and ( min-width: 768px ){
  .text{
    font-size: 24px;
    line-height: 33px;
  }
}