/*@import url( 'https://fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,500i|Lato&display=swap' );*/
@import url( 'https://fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,500i&display=swap' );


/* basics */
body.global-warming{
  padding: 112px 0;
  background-color: #fff;
  /*font: 16px/22px 'EB Garamond', serif;*/
  /*font: 15px/24px 'Lato', sans-serif;*/
  font: 21px/28px 'EB Garamond', serif;
}
.global-warming .content h1,
.global-warming .content h2,
.global-warming .content h3{
  margin: 0 0 28px;
  font-weight: 500;
  /*font-family: 'EB Garamond', serif;*/
}
.global-warming .content h1{
  font-size: 60px;
  line-height: 56px;
}
.global-warming .content h2{
  margin-top: 56px;
  font-size: 36px;
  line-height: 42px;
}
.global-warming .content h3{
  font-size: 24px;
  line-height: 42px;
}
.global-warming .content h3 button{
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
}
.global-warming p{
  margin: 0 0 28px;
}
.global-warming ::-moz-selection{
  background-color: #f00;
}
.global-warming ::selection{
  background-color: #f00;
}
.global-warming .content a{
  background-image: -moz-linear-gradient( top, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );
  background-image: -webkit-linear-gradient( top, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );
  background-image: linear-gradient( 180deg, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );
  color: #292929;
  text-decoration: none;
}
.global-warming .content a:hover{
  background-color: #c0c0c0;
  /*background-image: -moz-linear-gradient( top, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );
  background-image: -webkit-linear-gradient( top, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );
  background-image: linear-gradient( 180deg, rgba( 0, 0, 0, 0 ) 26px, #c0c0c0 26px, rgba( 0, 0, 0, 0 ) 27px );*/
}
.global-warming .content ul,
.global-warming .content ol{
  margin: 0 0 28px 0;
}
.global-warming .content ul{
  padding: 0 0 0 21px;
}
.global-warming .content ol,
.global-warming figure{
  padding: 0;
}
.global-warming ol{
  padding: 0 0 0 19px;
}
.global-warming figure{
  margin: 0;
}
.global-warming .content button{
	padding: 0;
	border: none;
  font: 21px/28px 'EB Garamond', serif;
  /*font: 16px/24px 'Lato', sans-serif;*/
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
.global-warming .content button::-moz-focus-inner{
	padding: 0;
	border: 0;
}
.global-warming img{
  max-width: 100%;
  height: auto;
}
.global-warming .content a,
.global-warming .content button{
	-moz-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}


/* grid */
.col-text,
figure.graph .col-graph,
figure.graph figcaption{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 15px;
}
.col-text{
  float: none;
  max-width: 560px;
  margin: 0 auto;
}


/* template */
/*body.global-warming{
  padding-top: 84px;
}
.global-warming nav.page{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  padding-top: 28px;
  margin-bottom: 84px;
}
.global-warming a.home{
  position: relative;
  display: inline-block;
  width: 41px; *//* guess # *//*
  height: 49px; *//* guess # *//*
  background-image: none;
}
.global-warming a.home:hover{
  background-color: transparent;
}
.global-warming a.home ul.block{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: auto;
  -webkit-transform-style: preserve-3d;
	-webkit-transform: rotateX( 35.2643896828deg ) rotateY( 45deg ); *//* isometric x: -Math.atan( 1 / Math.sqrt( 2 ) ) *//*
	transform: rotateX( 35.2643896828deg ) rotateY( 45deg );
}
.global-warming a.home li{
  list-style: none;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba( 41, 41, 41, 0.1 );
}
.global-warming a.home li:nth-child( 1 ){
	-webkit-transform: rotate( 0deg ) translateZ( 15px );
	transform: rotate( 0deg ) translateZ( 15px );
}
.global-warming a.home li:nth-child( 2 ){
	-webkit-transform: rotateX( -90deg ) translateZ( 15px );
	transform: rotateX( -90deg ) translateZ( 15px );
}
.global-warming a.home li:nth-child( 3 ){
	-webkit-transform: rotateX( 90deg ) translateZ( 15px );
	transform: rotateX( 90deg ) translateZ( 15px );
}
.global-warming a.home li:nth-child( 4 ){
	-webkit-transform: rotateY( -90deg ) translateZ( 15px );
	transform: rotateY( -90deg ) translateZ( 15px );
}
.global-warming a.home li:nth-child( 5 ){
	-webkit-transform: rotateY( 90deg ) translateZ( 15px );
	transform: rotateY( 90deg ) translateZ( 15px );
}
.global-warming a.home li:nth-child( 6 ){
  -webkit-transform: rotateY( 180deg ) translateZ( 15px );
  transform: rotateY( 180deg ) translateZ( 15px );
}*/
button.share{
  float: right;
}
button.share svg{
  width: 30px;
  height: 31px;
}
.col-text h1 + p::first-letter{
  float: left;
  margin: 0 3px -4px 0;
  /*font-weight: 500;*/
  /*font-size: 60px;
  line-height: 56px;*/
  font-size: 96px;
  line-height: 84px;
}
/*.global-warming sup{
  padding: 0 2px;
  font-size: 12px;
}
.global-warming sup a{
  color: #292929;
  text-decoration: none;
}*/
.q-order{
  position: absolute;
  left: -20px;
}
body.global-warming hr{
  border: 0;
  border-top: 1px solid #292929;
  margin: 0 0 28px;
}
/*a.citation{
  position: relative;
  top: 0;
  color: #292929;
  text-decoration: none;
}
a.citation:hover{*/
  /*top: -2px;
  padding-bottom: 2px;*/
  /*text-decoration: underline;
}*/
/*a.citation > span{
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 0;
  width: 300px;
  padding: 15px;
  background-color: #292929;
  color: #fff;
  opacity: 0;
  -moz-transition: opacity 200ms ease-out, z-index 1ms 201ms;
  -webkit-transition: opacity 200ms ease-out, z-index 1ms 201ms;
  transition: opacity 200ms ease-out, z-index 1ms 201ms;
}
a.citation:hover > span{
  display: block;
  z-index: 1;
  opacity: 1;
  -moz-transition: opacity 200ms ease-out;
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}*/
.col-text em,
.global-warming figcaption em,
aside.reference em,
ol.quick-reference em{
  margin-right: 3px;
}
figure.graph{
  padding: 28px 0;
  margin: 28px 0;
  background-color: #f4f4f4;
}
figure.graph .col-graph,
figure.graph figcaption{
  float: left;
}
figure.graph canvas{
  display: block;
  background-color: #fff;
}
footer.page{
  margin-top: 56px;
}
button.references{
  position: relative;
  padding-right: 27px;
}
button.references:hover{
  padding-right: 29px;
}
button.references:after{
  content: '';
  position: absolute;
  top: 14px;
  right: 0;
  border: 9px solid transparent;
  border-left-color: #292929;
}
aside.reference ol{
  list-style: none;
  word-wrap: break-word;
}
aside.reference li{
  padding-left: 36px;
  margin-bottom: 28px;
}
aside.reference li::first-letter{
  margin-left: -36px;
}
ol.quick-reference{
  list-style: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
}
ol.quick-reference > li{
  position: absolute;
  right: 0;
  left: 0;
  padding: 28px 0;
  background-color: #fff;
	-moz-transition: all 400ms ease-out;
	-webkit-transition: all 400ms ease-out;
	transition: all 400ms ease-out;
}
ol.quick-reference > li.on{
  -moz-box-shadow: 0 10px 20px rgba( 0, 0, 0, .1 );
  -webkit-box-shadow: 0 10px 20px rgba( 0, 0, 0, .1 );
  box-shadow: 0 10px 20px rgba( 0, 0, 0, .1 );
  -ms-transform: translateY( -100% );
	-webkit-transform: translateY( -100% );
	transform: translateY( -100% );
}
ol.quick-reference > li > div{
  max-width: 766px; /* make some ratio of text max-width? */
  padding: 0 15px;
  margin: 0 auto;
}


@media screen and ( min-width: 768px ){

  /* basics */
  body.global-warming{
    /*font-size: 21px;
    line-height: 32px;*/
    /*font: 21px/28px 'EB Garamond', serif;*/
    /*font-size: 24px;
    line-height: 35px;*/
  }
  .global-warming .content h1{
    font-size: 96px;
    line-height: 84px;
  }
  /*button{
    font-size: 21px;
    line-height: 28px;
  }*/


  /* template */
  figure.graph .col-graph,
  figure.graph figcaption{
    width: 50%;
  }

}