/* simple slide-show */
.simple-slide-show{
	position: relative;
	overflow: hidden;
}
.simple-slide-show figure:before,
.simple-slide-show figure:after,
.slide-index,
.slide-index > li,
.slide-control > div{
	-o-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
.simple-slide-show > ul,
.simple-slide-show > ul > li,
.simple-slide-show figure{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.simple-slide-show > ul{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;
}
.simple-slide-show-ready > ul > li{
	-o-transition: opacity 1000ms ease-out;
	-moz-transition: opacity 1000ms ease-out;
	-webkit-transition: opacity 1000ms ease-out;
	transition: opacity 1000ms ease-out;
}
.simple-slide-show.fade > ul > li{
	opacity: 0;
}
.simple-slide-show > ul > .on{
	z-index: 3;
}
.simple-slide-show.fade > ul > .off,
.simple-slide-show.fade > ul > .on{
	opacity: 1;
}
.simple-slide-show > ul > .to-left,
.simple-slide-show > ul > .to-right,
.simple-slide-show > ul > .off{
	z-index: 2;
}
.simple-slide-show figure{
	margin: 0;
	background-position: center;
	background-size: cover;
}
.from-left figure{
	-webkit-animation: from-left 1s ease-in-out both;
	animation: from-left 1s ease-in-out both;
}
.from-right figure{
	-webkit-animation: from-right 1s ease-in-out both;
	animation: from-right 1s ease-in-out both;
}
.to-left figure{
	-webkit-animation: to-left 1s ease-in-out both;
	animation: to-left 1s ease-in-out both;
}
.to-left-init figure{
	-webkit-animation: to-left 0s both;
	animation: to-left 0s both;
}
.to-right figure{
	-webkit-animation: to-right 1s ease-in-out both;
	animation: to-right 1s ease-in-out both;
}
@-webkit-keyframes from-left{
	0%{ -webkit-transform: translateX( -100% ); }
	100%{ -webkit-transform: translateX( 0% ); }
}
@keyframes from-left{
	0%{ transform: translateX( -100% ); }
	100%{ transform: translateX( 0% ); }
}
@-webkit-keyframes from-right{
	0%{ -webkit-transform: translateX( 100% ); }
	100%{ -webkit-transform: translateX( 0% ); }
}
@keyframes from-right{
	0%{ transform: translateX( 100% ); }
	100%{ transform: translateX( 0% ); }
}
@-webkit-keyframes to-left{
	0%{ -webkit-transform: translateX( 0% ); }
	100%{ -webkit-transform: translateX( -100% ); }
}
@keyframes to-left{
	0%{ transform: translateX( 0% ); }
	100%{ transform: translateX( -100% ); }
}
@-webkit-keyframes to-right{
	0%{ -webkit-transform: translateX( 0% ); }
	100%{ -webkit-transform: translateX( 100% ); }
}
@keyframes to-right{
	0%{ transform: translateX( 0% ); }
	100%{ transform: translateX( 100% ); }
}
.slide-control{
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 4;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
}
.slide-control.prev{
	left: 0;
}
.slide-control.next{
	right: 0;
}
.simple-slide-show:hover .slide-control:hover > div{
	opacity: 1;
}
.slide-index{
	position: absolute;
	z-index: 4;
	list-style: none;
}
.index-underline{
	content: '';
	position: absolute;
	opacity: 0;
	-o-transition: opacity 200ms ease-out, left 1000ms ease-out;
	-moz-transition: opacity 200ms ease-out, left 1000ms ease-out;
	-webkit-transition: opacity 200ms ease-out, left 1000ms ease-out;
	transition: opacity 200ms ease-out, left 1000ms ease-out;
}
.slide-index > li{
	float: left;
	cursor: pointer;
	opacity: 0;
}
.simple-slide-show:hover .slide-index > li,
.simple-slide-show:hover .slide-index.underline > li + .index-underline{
	opacity: 0.66;
}
/* simple slide-show: loading */
.simple-slide-show figure:before,
.simple-slide-show figure:after{
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}
.simple-slide-show figure:after{
	width: 66px;
	height: 33px;
	-webkit-border-bottom-right-radius: 66px;
	-webkit-border-bottom-left-radius: 66px;
	-moz-border-radius-bottomright: 66px;
	-moz-border-radius-bottomleft: 66px;
	border-bottom-right-radius: 66px;
	border-bottom-left-radius: 66px;
	margin: auto;
	-ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
	-webkit-animation: spinShifted 1s linear infinite;
	animation: spinShifted 1s linear infinite;
}
@-webkit-keyframes spinShifted{
	0%{ -webkit-transform: translateY( 50% ) rotate( 0deg ); }
	100% { -webkit-transform: translateY( 50% ) rotate( 360deg ); }
}
@keyframes spinShifted{
	0%{ transform: translateY( 50% ) rotate( 0deg ); }
	100%{ transform: translateY( 50% ) rotate( 360deg ); }
}
.simple-slide-show-ready figure:before,
.simple-slide-show-ready figure:after{
	z-index: -1;
	opacity: 0;
}
/* simple slide-show: oversimplified effect kill on Android */
/*.ua-android .from-left figure,
.ua-android .from-right figure,
.ua-android .to-left figure,
.ua-android .to-right figure{
	-webkit-animation: none;
	animation: none;
}*/
/* simple slide-show: basic theme */
.simple-slide-show figure{
	padding: 30px 92px 48px;
}
.slide-control{
	top: 0;
	bottom: 0;
	display: none;
	width: 100px;
	height: 100%;
	margin: auto 0;
	background-color: transparent;
}
.slide-control > div{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 1px;
	height: 50px;
	margin: auto;
	background-color: #fff;
	opacity: 0;
}
.slide-control.prev > div{
	-ms-transform: translateX( 50px );
	-webkit-transform: translateX( 50px );
	transform: translateX( 50px );
}
.slide-control.next > div{
	-ms-transform: translateX( -50px );
	-webkit-transform: translateX( -50px );
	transform: translateX( -50px );
}
.slide-control > div:before,
.slide-control > div:after{
	content: '';
	position: absolute;
	width: 35px;
	height: 1px;
	background-color: #fff;
}
.slide-control.prev > div:before,
.slide-control.next > div:before{
	top: 12px;
}
.slide-control.prev > div:after,
.slide-control.next > div:after{
	bottom: 12px;
}
.slide-control.prev > div:before,
.slide-control.prev > div:after{
	right: -5px;
}
.slide-control.prev > div:before{
	-ms-transform: rotate( -45deg );
	-webkit-transform: rotate( -45deg );
	transform: rotate( -45deg );
}
.slide-control.prev > div:after{
	-ms-transform: rotate( 45deg );
	-webkit-transform: rotate( 45deg );
	transform: rotate( 45deg );
}
.slide-control.next > div:before,
.slide-control.next > div:after{
	left: -5px;
}
.slide-control.next > div:before{
	-ms-transform: rotate( 45deg );
	-webkit-transform: rotate( 45deg );
	transform: rotate( 45deg );
}
.slide-control.next > div:after{
	-ms-transform: rotate( -45deg );
	-webkit-transform: rotate( -45deg );
	transform: rotate( -45deg );
}
.simple-slide-show:hover .slide-control > div{
	opacity: 0.66;
	-ms-transform: translateX( 0px );
	-webkit-transform: translateX( 0px );
	transform: translateX( 0px );
}
.slide-index{
	left: 50%;
	bottom: 21px;
	padding: 0 1px; /* remedy cropping glitch on some screens */
	margin: 0;
	-ms-transform: translateX( -50% );
	-webkit-transform: translateX( -50% );
	transform: translateX( -50% );
}
.slide-index > li{
	width: 12px;
	height: 12px;
	border: 1px solid #fff;
	margin-left: 16px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.slide-index > li:first-child{
	margin: 0;
}
.simple-slide-show:hover .slide-index > li:hover{
	opacity: 1;
}
.index-underline{
	bottom: -6px;
	left: 1px;
	width: 12px;
	height: 1px;
	background-color: #fff;
}
.simple-slide-show figure:before{
	background-color: #fff;
}
.simple-slide-show figure:after{
	background-color: #000;
}


@media screen and ( min-width: 992px ){

  /* slide-show: custom */
  .simple-slide-show.slide-controls .slide-control{
    display: block;
  }

}
