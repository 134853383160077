.splash,
.splash body{
	height: 100%;
}
/*.splash body{
	font-size: 0;
}*/
.splash body.no-webgl{
	background-color: #ff007f;
}
.splash button.menu div,
.splash body:not( .show-menu-overlay ) button.menu span{
	background-color: #fff;
}
.splash .social__link{
	color: #fff;
}
.splash .show-menu-overlay button.menu div{
	background-color: transparent;
}
.splash canvas{
	float: left;
}