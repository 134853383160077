.container{
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  display: none;
  max-height: 60vh;
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
}

.container.open{
  display: block;
}
