html.playlist-concerts,
html.playlist-concerts body,
html.playlist-concerts article.content{
  height: 100%;
}
html.playlist-concerts body{
  /*background-color: #a689fa;*/
  background-color: #e0e0e0;
  background-image: -moz-linear-gradient( 135deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 0.16 ) 100% );
  background-image: -webkit-linear-gradient( 135deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 0.16 ) 100% );
  background-image: linear-gradient( 135deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 0.16 ) 100% );
  font: 16px/22px 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.playlist-concerts__container{
  position: relative;
  padding-bottom: 60px;
  /*background-color: rgba( 255, 255, 255, 0.75 );*/
  background-color: rgba( 255, 255, 255, 0.9375 );
  -moz-box-shadow: 0 5px 15px rgba( 0, 0, 0, .05 );
  -webkit-box-shadow: 0 5px 15px rgba( 0, 0, 0, .05 );
  box-shadow: 0 5px 15px rgba( 0, 0, 0, .05 );
}
.playlist-concerts__headline{
  padding-top: 198px;
  /*padding-bottom: 42px;*/
  padding-bottom: 30px;
  margin: 0;
  background-color: rgba( 0, 0, 0, 0.0625 );
  font-size: 60px;
  line-height: 55px;
}
.playlist-concerts-app{
  /*background-color: rgba( 255, 255, 255, 0.75 );*/
  padding-top: 30px;
  padding-bottom: 30px;
}
.select-playlist label span{
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.playlist-concerts__api-image{
  vertical-align: middle;
  opacity: 0.87;
}
.playlist-concerts__api-image--songkick{
  margin-top: 6px;
}


/* put this in a shared button component */
.btn-primary{
  height: 52px;
  -moz-border-radius: 999px;
  -webkit-border-radius: 999px;
  border-radius: 999px;
  -moz-box-shadow: 0 10px 20px rgba( 0, 0, 0, .15 );
  -webkit-box-shadow: 0 10px 20px rgba( 0, 0, 0, .15 );
  box-shadow: 0 10px 20px rgba( 0, 0, 0, .15 );
  background-color: #9754fa;
  color: #fff;
}


@media screen and ( min-width: 768px ){

  .playlist-concerts__headline{
    padding-top: 84px;
  }
  .playlist-concerts__container{
    border: 15px solid rgba( 0, 0, 0, 0.0625 );
  }

}


@media screen and ( min-width: 1200px ){

  .playlist-concerts__container{
    min-height: 100%;
    padding: 165px 30px 60px;
  }
  .playlist-concerts__headline{
    position: absolute;
    top: 0;
    left: 0;
    padding: 32px 15px 42px 55px;
  }

}